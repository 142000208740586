<template>
  <panel :title="$t('Advanced Search')" noButton="true">
    <div class='row' @keyup.enter="search()" @keyup.esc="resetFilters()">
      <div
        v-for="field in fields" :key="field.key"
        :class="`col-${field.col? field.col : 12} ${field.hidden? 'hidden':''}`" class="pr-lg mb-3">
        <legend class="col-form-label pt-0">{{field.placeholder}}</legend>
        <b-form-select v-if="field.type=='select'"
          v-model="selectedFields[field.key]"
          :options="field.options"/>

        <date-picker
            v-else-if="['date', 'datetime'].indexOf(field.type) > -1"
            :config="getConfig(field)"
            :placeholder="field.type === $t('Enter the date')"
            v-model="selectedFields[field.key]"
          />
        <b-form-checkbox-group
          v-else-if="['boolean'].indexOf(field.type) > -1"
          :id="field.name"
          v-model="selectedFields[field.key]"
          :options="field.options"
          :name="field.name"
          @change="changeCheckbox($event, field.key)"
          buttons
        />
        <b-form-checkbox
          v-else-if="['checkbox'].indexOf(field.type) > -1"
          :id="field.name"
          v-model="selectedFields[field.key]"
          value="True"
          :name="field.name"
        >
          {{ field.name }}
        </b-form-checkbox>
        <b-form-input v-else
          v-model="selectedFields[field.key]"
          :type="field.type"></b-form-input>
      </div>
    </div>
    <div class="text-right">
      <button
        class="btn btn-default mr-3"
        @click.prevent="resetFilters">
        {{ 'Reset' | translate }}
      </button>
      <button
        class="btn btn-success"
        @click='search()'>
        {{ 'Search' | translate }}
      </button>
    </div>
  </panel>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AdvancedSearch',
  props: ['fields', 'reset'],
  watch: {
    reset: function () {
      this.$set(this, 'selectedFields', {})
      this.$emit('active', false)
    }
  },
  data () {
    return {
      selectedFields: {},
      cleanFilters: {},
      fieldsToWatch: {},
      patterns: {
        date: 'DD/MM/YYYY'
      },
      localeMessages: undefined
    }
  },
  methods: {
    getConfig (field) {
      let config = {}
      if (field.type === 'date') {
        // date config
        config = {
          format: 'L',
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        }
        config.minDate = new Date(null)
        if (field.minDate && this.selectedFields[field.minDate]) {
          const date = moment(this.selectedFields[field.minDate], 'DD/MM/YYYY')
          if (date.isValid()) {
            config.minDate = new Date(date.format())
            if (this.selectedFields[field.key] && date > moment(this.selectedFields[field.key], 'DD/MM/YYYY')) {
              setTimeout(this.$set(this.selectedFields, field.key, null), 500)
            }
          }
        }
      } else if (field.type === 'time') {
        // time config
        config = {
          format: 'HH:mm',
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        }
      } else if (field.type === 'datetime') {
        // datetime config
        config = {
          format: 'L LT',
          sideBySide: true,
          useCurrent: false,
          showClear: true,
          locale: this.$i18n.locale()
        }
      }
      this.$set(config, 'icons', this.icons)
      return config
    },
    initData () {
      this.fields.forEach(element => {
        if (['date', 'datetime'].indexOf(element.type) > -1) {
          if (element.initialValue) {
            this.$set(this.selectedFields, element.key, element.initialValue)
          } else {
            this.$set(this.selectedFields, element.key, undefined)
          }
        } else if (element.type === 'select') {
          this.$set(this.selectedFields, element.key, element.initialValue)
        } else {
          this.$set(this.selectedFields, element.key, element.initialValue)
        }
      })
    },
    setPatterns () {
      const locale = this.$i18n ? this.$i18n.locale : 'en-us'
      // const patterns = {
      //   'en-us': 'YYYY/MM/DD',
      //   'it-it': 'DD/MM/YYYY'
      // }
      this.patterns.date = process.env.PATTERNS[locale]
    },
    setLocaleMessages () {
      const locale = this.$i18n ? this.$i18n.locale : 'en-us'
      this.localeMessages = this.$i18n.messages[locale]['date-picker']
    },
    changeCheckbox (value, key) {
      if (!Array.isArray(value)) {
        this.$set(this.selectedFields, key, [value])
      } else if (value.length === 0) {
        this.$set(this.selectedFields, key, undefined)
      } else if (value.length === 1) {
        this.$set(this.selectedFields, key, value)
      } else {
        const valueSelected = value.filter((field) => {
          return this.selectedFields[key][0] !== field
        })
        this.$set(this.selectedFields, key, valueSelected)
      }
    },
    search: function () {
      this.fields.forEach(element => {
        if (element.type === 'date') {
          this.cleanFilters[element.key] = undefined
          if (this.selectedFields[element.key]) {
            const dateObj = moment(
              this.selectedFields[element.key], this.patterns.date
            )

            if (element.originalType === 'datetime') {
              this.cleanFilters[element.key] = dateObj.toISOString()
            } else {
              this.cleanFilters[element.key] = dateObj.format('YYYY-MM-DD')
            }
          }
        } else if (element.type === 'boolean') {
          if (this.selectedFields[element.key] && Array.isArray(this.selectedFields[element.key]) && this.selectedFields[element.key].length === 0) {
            this.cleanFilters[element.key] = undefined
          } else {
            this.cleanFilters[element.key] = this.selectedFields[element.key]
          }
        } else {
          this.cleanFilters[element.key] = this.selectedFields[element.key]
        }
      })
      const active = Object.keys(this.cleanFilters).some((key) => {
        return (
          typeof this.cleanFilters[key] !== 'undefined' &&
          this.cleanFilters[key] !== null
        )
      })
      this.$emit('search', this.cleanFilters)
      this.$emit('active', active)
    },
    resetFilters () {
      const filters = Object.assign({}, this.cleanFilters)
      Object.keys(this.cleanFilters).forEach((key) => {
        if (typeof this.cleanFilters[key] === 'undefined') {
          delete filters[key]
        }
      })
      this.initData()
      this.$emit('reset', Object.keys(filters))
      this.$emit('active', false)
      this.$set(this, 'selectedFields', {})
    }
  },
  beforeMount: function () {
    this.initData()
    // this.setPatterns()
    // this.setLocaleMessages()
  }
}
</script>
