<template>
  <i
    class="fas fa-circle status-filter icon-active-filters-color"
    :title="getLabelFilters"
  />
</template>

<script>
export default {
  name: 'IconActiveFilters',
  props: {
    filters: { required: true }
  },
  computed: {
    getLabelFilters () {
      let labelFilters = `${this.$t('Active filters')}: `
      if (!this.filters) return
      Object.keys(this.filters).forEach((key) => {
        if (typeof this.filters[key] !== 'undefined') {
          let filterName = key
            .replace('filter__', '')
            .replace('__', ' ')
            .replace('_', ' ')
          filterName = filterName.split(' ')[0]
          filterName = filterName.charAt(0).toUpperCase() + filterName.slice(1)
          labelFilters = labelFilters + `${this.$t(filterName)}, `
        }
      })
      return labelFilters
    }
  }
}
</script>
