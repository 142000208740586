<template>
  <div class="list-view">
    <div class="panel-header-primary">
      <slot name="header-primary">
        <div class="panel-header-title">
          <action-select
            v-if="actionSelect.canSeeActionSelect"
            :list="fields.list"
            :actionOption="actionSelect.actionOption"
            :actionOptions="actionSelect.actionOptions"
            :selectedList="actionSelect.selectedList"
            :selectAll="actionSelect.selectAll"
            @select-action="$emit('select-action', $event)"
            @select-or-deselect-all-queryset="
              $emit('select-or-deselect-all-queryset', $event)
            "
            @do-action="$emit('do-action', $event)"
          />
          <pagination-select
            v-if="pagination.showPagination"
            :perPage="pagination.perPage"
            :pageOptions="pagination.pageOptions"
            @select="$emit('select-per-page', $event)"
          />
        </div>
        <div class="panel-header-actions">
          <slot name="other-headers-buttons" />
          <b-btn
            v-if="fields.canAddInstance"
            variant="primary"
            class="ml-2"
            @click="$emit('open-edit-form')"
          >
            <i class="fas fa-plus" :alt="'Add' | translate" />
            {{ 'Add' | translate }}
          </b-btn>
          <b-btn
            v-if="advancedSearch.searchFields && advancedSearch.searchFields.length > 0"
            variant="outline-primary"
            class="ml-2"
            @click.prevent="
              $emit(
                'show-advanced-search',
                !advancedSearch.showAdvancedSearch
              )
            "
          >
            <i
              class="fas fa-search" :alt="'Advanced Search' | translate" />
              <span class="d-none d-md-block">{{ 'Advanced Search' | translate }}</span>
              <span class="d-block d-md-none">{{ 'Search' | translate }}</span>
          </b-btn>
          <b-btn
            v-if="fields.showCommonFilters"
            variant="outline-primary"
            class="ml-2"
            @click.prevent="toggleRightSidebar"
          >
            <i class="fas fa-filter" :alt="'Search' | translate" />
            {{ 'Filters' | translate }}
          </b-btn>
        </div>
      </slot>
    </div>
    <slot name="body"></slot>
    <slot name="footer" v-if="pagination.showFooterPagination">
      <footer-pagination
        :list="fields.list"
        :per-page="pagination.perPage"
        :page="pagination.page"
        @change="$emit('change-page', $event)"
      />
    </slot>
  </div>
</template>

<script>
import ActionSelectComponent from './action-select/ActionSelectComponent'
import PaginationSelectComponent from './pagination/PaginationSelectComponent'
import FooterPaginationComponent from './pagination/FooterPaginationComponent'

export default {
  name: 'ThuxListComponent',
  props: {
    // component fields
    fields: { required: true },

    // pagination
    pagination: { required: true },

    // action select
    actionSelect: { required: true },

    // advanced search
    advancedSearch: { required: true }
  },
  components: {
    'action-select': ActionSelectComponent,
    'pagination-select': PaginationSelectComponent,
    'footer-pagination': FooterPaginationComponent
  },
  methods: {
    toggleRightSidebar () {
      document.querySelectorAll('.wrapper').forEach(
        wrapper => wrapper.classList.toggle('sidebar-collapsed')
      )
    }
  }
}
</script>
