<template>
  <b-row class="w-100">
    <b-col md="2" class="d-flex align-items-center pl-3 py-2 pb-md-none">
      <b>{{ list.count }}</b> &nbsp;{{ 'OBJECTS' | translate }}
    </b-col>
    <b-col md="8" class="d-flex align-items-center justify-content-center">
      <div>
        <b-pagination
          class="my-0"
          :total-rows="list.count"
          :per-page="perPage"
          :value="page"
          @change="$emit('change', $event)"
        />
      </div>
      <div class="pl-2">
        {{ 'OF' | translate }} <b>{{ list.last }}</b>
        {{ 'PAGES' | translate }}
      </div>
    </b-col>
    <b-col md="2" class="d-flex align-items-center justify-content-end pr-0 py-2">
      <b-input-group
        @keydown.enter="
          isValidPageToJump ? $emit('change', pageToJump) : undefined
        "
      >
        <b-form-input
          v-model="pageToJump"
          :placeholder="'go to page' | translate"
        />
        <b-input-group-append>
          <b-btn
            :disabled="!isValidPageToJump"
            variant="outline-primary"
            @click.prevent="$emit('change', pageToJump)"
            >>></b-btn
          >
        </b-input-group-append>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FooterPaginationComponent',
  props: {
    list: { required: true },
    perPage: { required: true },
    page: { required: true }
  },
  data () {
    return {
      pageToJump: undefined
    }
  },
  computed: {
    isValidPageToJump () {
      return (
        this.pageToJump &&
        parseInt(this.pageToJump) > 0 &&
        this.pageToJump.toString() === parseInt(this.pageToJump).toString()
      )
    }
  }
}
</script>
