<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed panel-with-actions">
        <thux-list-header
          v-if="showHeader"
          :title="title"
          :go-to="goToRouter"
          :icon-active-filters="getIconFiltersFields"
          :intermediate-breadcrumbs="intermediateBreadcrumbs"
          :action-buttons="actionButtons"
          @action-call="actionCall"
        />
        <component-edit
          v-if="showEditForm && editComponent"
          :id="componentId"
          :editParams="editParams"
          @close-form='closeEditForm'
        />
        <component-detail
          v-if="showDetailForm && detailComponent"
          :id="componentId"
          :detailParams="detailParams"
          @close-form='closeDetailForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="panelTitle" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
    <WarningDialog
      v-if="showWarningDialog"
      ref-id="modal-warning"
      :no-close-on-backdrop="true"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="false"
      :title="$t('WARNING')"
      :body="warningDialogMessage"
      :button-confirm-text="'OK' | translate"
      @warning-dialog-close="closeWarningDialogModal"
    >
    </WarningDialog>
  </div>
</template>

<script>
import ActionSelectMixin from './action-select/ActionSelectMixin'
import ThuxListComponent from './ThuxListComponent'
import PaginationMixin from './pagination/PaginationMixin'
import AdvancedSearchMixin from '../advanced-search/AdvancedSearchMixin'
import ThuxListHeader from './ThuxListHeader'
import AdvancedSearch from '../advanced-search/AdvancedSearch'
import IconActiveFiltersMixin from './icon-active-filters/IconActiveFiltersMixin'
import WarningDialog from '@/components/WarningDialog'
import { mapGetters } from 'vuex'
import { CommonFilterTypes } from '../../pages/common-filters/store'

export default {
  name: 'ThuxListMixin',
  props: {
    goBack: { default: false },
    isDetail: { default: false }
  },
  mixins: [
    PaginationMixin,
    ActionSelectMixin,
    AdvancedSearchMixin,
    IconActiveFiltersMixin
  ],
  components: {
    'advanced-search': AdvancedSearch,
    'thux-list-header': ThuxListHeader,
    'thux-list-component': ThuxListComponent,
    WarningDialog
  },
  watch: {
    reset: function () {
      this.getComponentList()
    }
  },
  data () {
    return {
      showHeader: true,
      componentId: null,
      title: '',
      subtitle: '',
      rolePerm: null,
      tableParams: {},
      intermediateBreadcrumbs: [],
      fieldName: 'id',
      searchFields: [],
      editParams: {},
      detailParams: {},
      showEditForm: false,
      showDetailForm: false,
      showCommonFilters: true,
      pageToJump: undefined,
      showAdvancedSearch: false,
      advancedSearch: {},
      activeFilters: false,
      goToRouter: null,
      editComponent: true,
      detailComponent: true,
      actionButtons: [],
      showWarningDialog: false
    }
  },
  created () {
    this.cleanAllData()
    this.getComponentList()
  },
  computed: {
    ...mapGetters({
      reset: CommonFilterTypes.COMMON_FILTERS.GETTERS.reset
    }),
    showList () {
      return true
    },
    getFields () {
      return {
        list: this.list,
        title: this.title,
        canAddInstance: this.canAddInstance,
        showCommonFilters: this.showCommonFilters
      }
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return false
    },
    panelTitle () {
      return `${this.title} ${this.subtitle}`
    }
  },
  methods: {
    openEditForm (id) {
      this.$set(this, 'componentId', id)
      this.$set(this, 'showEditForm', true)
      this.$set(this, 'showDetailForm', false)
    },
    closeEditForm () {
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'showEditForm', false)
    },
    openDetailForm (id) {
      this.$set(this, 'componentId', id)
      this.$set(this, 'showDetailForm', true)
      this.$set(this, 'showEditForm', false)
    },
    closeDetailForm () {
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showDetailForm', false)
    },
    getComponentList () {
      return this.setFilters({})
    },
    closeRightSidebar () {
      document.querySelectorAll('.wrapper').forEach(
        wrapper => wrapper.classList.toggle('sidebar-collapsed')
      )
    },
    actionCall (actionName) {
      this[actionName]()
    },
    closeWarningDialogModal () {
      this.showWarningDialog = false
    }
  }
}
</script>
