<script>
export default {
  name: 'IconActiveFiltersMixin',
  computed: {
    getIconFiltersFields () {
      return {
        filters: this.filters,
        activeFilters: this.activeFilters
      }
    }
  }
}
</script>
