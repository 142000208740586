<script>
import { hasPerm } from '../../PermissionHelper'

export default {
  name: 'ActionSelectComponentMixin',
  data () {
    return {
      title: '',
      apiUrlAction: undefined,
      fieldName: 'id',
      actionOption: { value: null, text: '---' },
      actionsCustom: [],
      actionEnablePermission: [],
      actionDisablePermission: [],
      actions: [
        { value: null, text: '---' },
        {
          value: 'enable',
          text: this.$t('Set as enable')
        },
        {
          value: 'disable',
          text: this.$t('Set as disable')
        }
      ]
    }
  },
  computed: {
    getActionSelectFields () {
      return {
        actionOption: this.actionOption,
        actionOptions: this.actionOptions,
        selectAll: this.selectAll,
        selectedList: this.selectedList,
        canSeeActionSelect: this.canSeeActionSelect
      }
    },
    actionOptions () {
      const _actions = this.actions.filter(element => {
        if (element.value === 'enable') {
          return hasPerm(this.actionEnablePermission)
        }
        if (element.value === 'disable') {
          return hasPerm(this.actionDisablePermission)
        }
        return true
      })
      return [..._actions, ...this.actionsCustom]
    }
  },
  methods: {
    selectOrDeselectAllQueryset (selectAll) {
      this.setSelectAll(selectAll)
      this.setSelectedList([])
    },
    doAction (actionType, fieldName = 'id') {
      if ((this.selectedList.length !== 0 || this.selectAll) && actionType) {
        const idList = this.selectedList.map((instance) => instance[fieldName])
        let counter = 0
        if (actionType === 'enable' || actionType === 'disable') {
          idList.forEach((id) => {
            this.setStatus({ id: id, status: actionType === 'disable' ? 0 : 1 }).then(() => {
              if (counter === idList.length - 1) {
                this.getList()
                this.setSelectedList([])
              }
              counter += 1
            })
          })
        } else {
          this.doCustomActions(actionType, idList, counter)
        }
      }
    },
    doCustomActions (actionType, idList, counter) {

    },
    setAction (action) {
      this.setSelectAll(false)
      this.$set(this, 'actionOption', action)
    }
  }
}
</script>
