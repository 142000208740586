<template>
  <div class="d-flex align-items-center">
    <span class="select-label"> {{ 'Pagination' | translate }}: </span>
    <multiselect-thux
      :show-clean-button="false"
      attributetype="select"
      dir="rtl"
      :hide-selected="true"
      :placeholder="perPage ? perPage.toString() : ''"
      :value="perPage"
      :options="pageOptions"
      @select="$emit('select', $event)"
    >
    </multiselect-thux>
  </div>
</template>

<script>
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'PaginationSelectComponent',
  props: {
    perPage: { required: true },
    pageOptions: { required: true }
  },
  components: {
    'multiselect-thux': MultiselectThux
  }
}
</script>
