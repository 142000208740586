<template>
  <b-modal
    :ref="refId"
    :content-class="contentClass ? `${contentClass} modal-confirm` : 'modal-confirm'"
    :no-enforce-focus="true"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :size="size || 'xl'"
    :hide-footer="hideFooter"
    centered
    @hidden="warningDialogOnHidden"
  >
    <div slot="modal-title">
      <span style="font-size: 1.563rem">{{ getTitle }}</span>
    </div>
    <slot name="body">
      <span style="font-size: 1.1rem" v-html="getBody"></span>
    </slot>
    <div slot="modal-footer">
      <slot name="buttons">
        <slot name="button-confirm">
          <b-btn
            ref="submit"
            :variant="confirmBtnVariant ? confirmBtnVariant : 'danger'"
            class="btn-wide"
            @click.prevent="closeModal()"
          >
            {{ getButtonConfirmText }}
          </b-btn>
        </slot>
        <slot name="button-extra" />
      </slot>
    </div>
  </b-modal>
</template>

<script>

const WarningDialogComponent = {
  name: 'WarningDialog',
  props: [
    'title',
    'body',
    'contentClass',
    'confirmBtnVariant',
    'size',
    'hideFooter',
    'noCloseOnBackdrop'
  ],
  data () {
    return {
      refId: 0
    }
  },
  created () {
    this.refId = this.generateUUID()
  },
  mounted () {
    this.openModal()
  },
  computed: {
    getTitle () {
      return this.$t(this.title ? this.title : 'WARNING')
    },
    getBody () {
      return this.$t(this.body ? this.body : 'Click OK button to continue')
    },
    getButtonConfirmText () {
      return this.$t(this.buttonConfirmText || 'OK')
    }
  },
  methods: {
    openModal () {
      this.$refs[this.refId].show()
    },
    closeModal () {
      this.$refs[this.refId].hide()
    },
    warningDialogOnHidden (evt) {
      this.$emit('warning-dialog-close', 'warning-dialog-close')
    },
    confirm () {
      this.closeModal()
    }
  }
}
export default WarningDialogComponent

export const WaningDialogMixin = {
  data () {
    return {
      showWarningModal: false
    }
  },
  components: {
    'warning-dialog': WarningDialogComponent
  },
  methods: {
    openWarningModal () {
      this.showWarningModal = true
    },
    closeWarningModal () {
      this.showWarningModal = false
    }
  }
}
</script>
