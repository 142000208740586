<template>
  <div class="d-flex align-items-center">
    <span class="select-label hide-mobile"> {{ 'Actions' | translate }}: </span>
    <multiselect-thux
      class="select-action mr-3 hide-mobile"
      attributetype="select"
      :show-clean-button="false"
      label="text"
      :placeholder="actionOption.text"
      :value="actionOption"
      :options="actionOptions"
      @select="$emit('select-action', $event)"
    >
    </multiselect-thux>
    <b-btn
      class="mr-2"
      v-show="actionOption.value"
      variant="outline-primary"
      size="sm"
      :disabled="selectedList.length === 0 && !selectAll"
      @click="$emit('do-action', actionOption.value)"
    >
      {{ 'Do' | translate }}
    </b-btn>
    <div class="panel-selected-items hide-mobile d-flex align-items-center">
      <span class="ml-2 mr-2">
        <b
          >{{ selectAll ? list.count : selectedList.length }}
          {{ 'of' | translate }} {{ list.count }}</b
        >
        {{ 'selected' | translate }}
      </span>
      <b-btn
        v-if="
          !selectAll &&
          (actionOption.value === 'export_csv' ||
            actionOption.value === 'export_xlsx')
        "
        class="hide-mobile"
        variant="link"
        size="sm"
        @click.prevent="$emit('select-or-deselect-all-queryset', true)"
      >
        {{ 'Select all' | translate }}
      </b-btn>
      <b-btn
        v-if="
          (selectAll &&
            (actionOption.value === 'export_csv' ||
              actionOption.value === 'export_xlsx')) ||
          selectedList.length > 0
        "
        variant="outline-primary"
        size="sm"
        @click.prevent="$emit('select-or-deselect-all-queryset', false)"
      >
        {{ 'Cancel the selection' | translate }}
      </b-btn>
    </div>
  </div>
</template>

<script>
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'ActionSelectComponent',
  props: {
    list: { required: true },
    actionOption: { required: true },
    actionOptions: { required: true },
    selectedList: { required: true },
    selectAll: { required: true }
  },
  components: {
    'multiselect-thux': MultiselectThux
  }
}
</script>
