<script>
export default {
  name: 'PaginationMixin',
  data () {
    return {
      showPagination: true,
      showFooterPagination: true
    }
  },
  computed: {
    getPaginationFields () {
      return {
        perPage: this.perPage,
        pageOptions: this.pageOptions,
        page: this.page,
        showPagination: this.showPagination,
        showFooterPagination: this.showFooterPagination
      }
    }
  }
}
</script>
