<script>
export default {
  name: 'AdvancedSearchMixin',
  data () {
    return {
      showAdvancedSearch: false,
      toggleAdvancedSearch: false,
      searchFields: [],
      activeFilters: false
    }
  },
  created () {
    this.$set(this, 'showAdvancedSearch', false)
    this.$set(this, 'toggleAdvancedSearch', false)
  },
  computed: {
    getAdvancedSearchFields () {
      return {
        showAdvancedSearch: this.showAdvancedSearch,
        toggleAdvancedSearch: this.toggleAdvancedSearch,
        searchFields: this.searchFields
      }
    }
  },
  methods: {
    openAdvancedSearch () {
      this.$set(this, 'showAdvancedSearch', !this.showAdvancedSearch)
      this.$set(this, 'toggleAdvancedSearch', true)
    },
    addFiltersToInstance (filtersToAdd) {
      const filters = { ...this.filters }
      if (filtersToAdd) {
        Object.keys(filtersToAdd).forEach((key) => {
          filters[key] = filtersToAdd[key]
        })
      }
      this.setFilters(filters)
    },
    removeFiltersToInstance (filters) {
      this.removeFilters(filters)
    }
  }
}
</script>
