<template>
  <div class="panel-header">
    <h1 class="page-header mr-2 d-flex align-items-center">
      {{ title | translate }}
      <icon-active-filters
        v-if="iconActiveFilters.activeFilters"
        :filters="iconActiveFilters.filters"
      />
      <div class="page-header-actions ml-2">
        <b-btn
          v-for="(button, index) in actionButtons" :key="index"
          variant="outline-primary"
          :title="button.title | translate"
          @click.prevent="$emit('action-call', button.actionName)"
        >
          <i :class="button.iconClass"></i>
        </b-btn>
      </div>
    </h1>
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">
        <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
      </li>
      <li class="breadcrumb-item" v-for="(breadcrumb, index) in intermediateBreadcrumbs" :key="index">
        <router-link :to="{ name: breadcrumb.routerName, params: breadcrumb.routerParams }">
            {{breadcrumb.title}}
          </router-link>
      </li>
      <li class="breadcrumb-item">{{ title | translate }}</li>
    </ol>
  </div>
</template>

<script>
import IconActiveFilters from './icon-active-filters/IconActiveFilters'

export default {
  name: 'ThuxListHeader',
  components: {
    'icon-active-filters': IconActiveFilters
  },
  props: {
    title: { required: true },
    subtitle: { },
    goTo: { default: { name: 'Dashboard', router: { name: 'dashboard' } } },
    iconActiveFilters: { default: {} },
    intermediateBreadcrumbs: { default: [] },
    actionButtons: {
      default: function () {
        return []
      }
    }
  }
}
</script>
